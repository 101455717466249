import * as React from "react"
import {FC, useState} from "react"
import {AppBar, Icon, Link, Toolbar, Typography} from "@mui/material";
import styled from "styled-components";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import {ReactComponent as Logo} from "./mechanical-arm-icon.svg";

const CardWrapper = styled.div`
  margin: auto;
  padding-bottom: 64px;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
`;


const pages = [
	{name: 'Kontakt', marker: 'contact'},
	{name: 'Maschinen', marker: 'machines'},
	{name: 'Anfahrt', marker: 'directions'},
	{name: 'Impressum', marker: 'impressum'}
];

function ResponsiveAppBar() {
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar position="sticky">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Icon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}>
						<Logo fill="white"/>
					</Icon>
					<Container sx={{
						padding: 0,
						display: {xs: 'none', md: 'flex'},
						flexGrow: 1,
					}}>
						<Business/>
					</Container>
					<Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon/>
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: {xs: 'block', md: 'none'},
							}}
						>
							{pages.map((page) => (
								<MenuItem key={page.name} onClick={handleCloseNavMenu}>
									<Typography textAlign="center"><Link href={`#${page.marker}`}>{page.name}</Link></Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Container sx={{
						display: {xs: 'grid', md: 'none'},
						flexGrow: 1,
					}}>
						<Business/>
					</Container>
					<Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
						{pages.map((page) => (
							<Button
								key={page.name}
								onClick={(e) => {
									e.preventDefault();
									handleCloseNavMenu();
									window.location.href = `#${page.marker}`;
								}}
								sx={{my: 2, color: 'white', display: 'block'}}
							>
								{page.name}
							</Button>
						))}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}

export default ResponsiveAppBar;

const Business: FC = () => {
	return <>
		<Typography
			variant="h6"
			noWrap
			component="a"
			href="/"
			sx={{
				mr: 2,
				fontFamily: 'sans-serif',
				letterSpacing: '.1rem',
				color: 'inherit',
				textDecoration: 'none',
				margin: 0,
				"padding-right": {xs: 0, md: "16px"}
			}}
		>
			Sachal
		</Typography>
		<Typography
			variant="h6"
			noWrap
			component="a"
			href="/"
			sx={{
				mr: 2,
				fontFamily: 'helvetica',
				fontWeight: 'bolder',
				letterSpacing: '.1rem',
				color: 'inherit',
				textDecoration: 'none',
				margin:0,
				padding: 0
			}}
		>
			Extrusionsmaschinen
		</Typography>
	</>
}

const Wrapper = styled.div`
  padding-bottom: 100px;
  width: 1080px;
  @media (max-width: 768px) {
    width: 100%;
  }`;

const Item = styled.div`
`;

const Header = styled.h2`
  padding-top: 70px;
`

const GridWrapper = styled.div`
  padding: 8px;
  display: grid;
`;

const Contact: FC = () => {
	return <Wrapper>
		<Header id="contact">Kontakt</Header>
		<GridWrapper>
			<Item>Peter Sachal</Item>
			<Item>Bahnhofstraße 6</Item>
			<Item>26345 Bockhorn</Item>
			<Item>Niedersachsen</Item>
			<Item>Deutschland</Item>
			<Item>Telefon +491724331938</Item>
		</GridWrapper>
	</Wrapper>
}

const Machines: FC = () => {
	return <Wrapper>
		<Header id="machines">Maschinen</Header>
		Sie finden eine Auswahl unserer Maschinen <Link href="https://www.maschinensucher.de/Haendler/79087/Sachal-EXTRUSIONSMASCHINEN-Bockhorn-Grabstede">hier</Link>.
	</Wrapper>
}

const Directions: FC = () => {
	return <Wrapper>
		<Header id="directions">Anfahrt</Header>
		<VideoContainer>
			<Frame
				width="853"
				height="480"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2380.489534047602!2d7.995559999999999!3d53.37029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b6ee1b60f20b5b%3A0x2cf66ad9d919ea7b!2sBahnhofstra%C3%9Fe%206%2C%2026345%20Bockhorn!5e0!3m2!1sen!2sde!4v1668958286460!5m2!1sen!2sde"
				loading="lazy" referrerPolicy="no-referrer-when-downgrade"
				title="Anfahrt"
			/>
		</VideoContainer>
	</Wrapper>
}

const Impressum: FC = () => {
	return <Wrapper>
		<Header id="impressum">Impressum</Header>
		<GridWrapper>
			<Item>Peter Sachal</Item>
			<Item>Nardorster Straße 65</Item>
			<Item>26123 Oldenburg</Item>
		</GridWrapper>

		<GridWrapper>
			<Item>Telefon: +49 172 433 1938</Item>
			<Item>E-Mail: sachal@t-online.de</Item>
			<Item>Internet: <Link href="https://www.sachal-extrusion.de">www.sachal-extrusion.de</Link></Item>
		</GridWrapper>

		<Item>Umsatzsteuer-Identifikationsnummer gemäß $27a Umsatzsteuergesetz: DE 190924659</Item>

		<Item>Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle Übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
			ausschließlich deren Betreiber verantwortlich.</Item>
		<Wrapper/>
	</Wrapper>
}

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
`;

const Frame = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border: 0;
`;

const YoutubeEmbed: FC<{ embedId: string }> = ({embedId}) => (
	<VideoContainer>
		<Frame
			width="853"
			height="480"
			src={`https://www.youtube.com/embed/${embedId}`}
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			title="Sachal Extrusionsmaschinen"
		/>
	</VideoContainer>
);

const Video: FC = () => {
	return <Wrapper>
		<VideoContainer>
			<YoutubeEmbed embedId="B13zMbii8bY"/>
		</VideoContainer>
	</Wrapper>;
}


export const Content: FC = () => {
	return <>
		<ResponsiveAppBar/>
		<CardWrapper>
			<Video/>
			<Contact/>
			<Machines/>
			<Directions/>
			<Impressum/>
		</CardWrapper>
	</>
}